import { Chip } from 'primereact/chip';
import React from 'react';

const Footer = ({ eventData }) => {
  return (
    <div
      style={{
        paddingTop: 12,
        paddingRight: 16,
        paddingBottom: 12,
        paddingLeft: 24,
        marginTop: 22,
        width: eventData.width,
        minHeight: eventData.height * 0.15,
        backgroundColor: 'var(--gray-600)',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
      id="footer"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: eventData.width * 0.45,
            height: eventData.height * 0.15,
            fontWeight: 600,
            backgroundColor: 'var(--gray-700)',
            padding: 16,
            borderRadius: 5,
          }}
        >
          Información de interés
        </div>
        <div
          style={{
            width: eventData.width * 0.45,
            backgroundColor: 'var(--gray-700)',
            padding: 16,
            borderRadius: 5,
          }}
        >
          <p style={{ fontWeight: 600 }}>Pagos y envíos</p>
          <p style={{ paddingTop: 16 }}>
            Podrás adquirir obra de <span style={{ fontWeight: 600 }}>Dio</span>{' '}
            por medio de transferencia bancaria, tarjetas de crédito y débito
            por medio de mercadopago. Enviamos a todo los países del mundo.
          </p>
        </div>
      </div>
      <div style={{ paddingTop: 16, display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Chip
            label="Sigueme en:"
            image="https://dioart.s3.us-east-1.amazonaws.com/6fa4601ec61f91bc113c6ac689807f97-c5-1080x1080.jpg"
          />
          <div
            style={{
              display: 'flex',
              width: 250,
              justifyContent: 'space-around',
            }}
          >
            <a
              href="https://www.instagram.com/artwithdio"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="pi pi-instagram icon"
                style={{ fontSize: '1.5rem' }}
              ></i>
            </a>
            <a
              href="https://www.tiktok.com/@artwithdio"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="pi pi-tiktok icon"
                style={{ fontSize: '1.5rem' }}
              ></i>
            </a>
            <a
              href="https://www.threads.net/@artwithdio"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-brands fa-threads icon"
                style={{ fontSize: '1.5rem' }}
              ></i>
            </a>
            <a
              href="https://wa.me/+5491127191959"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="pi pi-whatsapp icon"
                style={{ fontSize: '1.5rem' }}
              ></i>
            </a>
            <a
              href="https://twitter.com/dioart_"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="pi pi-twitter icon"
                style={{ fontSize: '1.5rem' }}
              ></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
