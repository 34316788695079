import React, { Fragment, useMemo, useState } from 'react';
import { useCollections } from 'Providers/CollectionsProviders';
import { useLocation, useNavigate } from 'react-router-dom';
import Gallery from './components/Gallery';
import { Button } from 'primereact/button';
import { formatNumberWithDots } from 'components/ContainerCollections/components/GaleryCollections/components/Item/utils';
import { InputText } from 'primereact/inputtext';
import LoadingContent from 'components/LoadingContent';

const Item = ({ eventData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('id');
  }, [location.search]);

  const collections = useCollections()?.collections;
  const { newItemShoppingCart, changeQuantyInShoppingCart } = useCollections();
  const item = collections?.find((item) => item._id === id);

  const [quantyBuy, setQuantyBuy] = useState(1);
  const minValue = 1;
  const maxValue = item?.quanty;

  const minusClick = () => {
    const inputValue = parseInt(quantyBuy - 1);

    if (inputValue >= minValue) {
      setQuantyBuy(inputValue);
    }
    changeQuantyInShoppingCart(item._id, '-');
  };
  const addClick = () => {
    const inputValue = parseInt(quantyBuy + 1);

    if (inputValue <= maxValue) {
      setQuantyBuy(inputValue);
    }
    changeQuantyInShoppingCart(item._id, '+');
  };
  if (!collections) {
    return <LoadingContent minHeight={eventData.height * 0.65} />;
  }

  const addItemInCart = () => {
    newItemShoppingCart(item._id, quantyBuy);
  };

  const goToShoppingCart = () => {
    navigate(`/shopping_cart`);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: eventData.width * 0.9,
        flexDirection: 'column',
        padding: 24,
        paddingTop: 32,
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Gallery width={eventData.width * 0.5} images={item.images} />
        <div
          style={{
            display: 'flex',
            flex: 1,
            minHeight: 700,
            padding: '72px 24px 0 48px',
            borderRadius: 5,
            justifyContent: 'flex-start',
            flexDirection: 'column',
            color: 'var(--gray-800)',
            fontWeight: 'normal',
            fontSize: '1em',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              fontSize: '2em',
              fontFamily: 'Franklin Gothic Medium',
            }}
          >
            {item.name}
          </div>
          {item.isAvailable && (
            <div
              style={{
                paddingTop: 24,
                fontSize: '1em',
              }}
            >{`$${formatNumberWithDots(item.price)} ARS`}</div>
          )}
          {item.isAvailable ? (
            <Fragment>
              <div
                style={{
                  paddingTop: 8,
                  fontSize: '0.7em',
                  fontFamily: 'sans-serif',
                }}
              >{`(Envío gratuito dentro de CABA, Buenos Aires)`}</div>
              <div
                style={{ paddingTop: 32, fontSize: '0.9em' }}
              >{`Cantidad`}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: 8,
                  width: 200,
                }}
              >
                <Button
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: 'var(--gray-300)',
                    border: 0,
                  }}
                  icon="pi pi-minus"
                  onClick={minusClick}
                />
                <InputText
                  keyfilter="int"
                  placeholder="Integers"
                  value={quantyBuy}
                  style={{
                    borderColor: 'var(--gray-300)',
                    backgroundColor: 'var(--gray-50)',
                    color: 'var(--gray-900)',
                    textAlign: 'center',
                    width: 40,
                    height: 30,
                  }}
                />
                <Button
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: 'var(--gray-300)',
                    border: 0,
                  }}
                  icon="pi pi-plus"
                  onClick={addClick}
                />
              </div>
              <Button
                label="Añadir al carrito"
                severity="secondary"
                style={{
                  borderColor: 'var(--gray-400)',
                  color: 'var(--gray-600)',
                  marginTop: 24,
                }}
                outlined
                onClick={addItemInCart}
              />
              <Button
                onClick={goToShoppingCart}
                style={{ marginTop: 8 }}
                label="Ir al carrito "
                severity="info"
                raised
              />
            </Fragment>
          ) : (
            <Button
              label="Vendido"
              severity="secondary"
              style={{
                borderColor: 'var(--red-600)',
                color: 'var(--red-600)',
                marginTop: 24,
              }}
              outlined
            />
          )}

          <div style={{ paddingTop: 32 }}>{`Técnica: ${item.technique}`}</div>
          <div style={{ paddingTop: 16 }}>{`Medidas: ${item.measure}`}</div>
          <div
            style={{ paddingTop: 16 }}
          >{`Fecha de realización: ${item.completionDate}`}</div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Item;
