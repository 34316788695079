import React from 'react';
import { Galleria } from 'primereact/galleria';

const Gallery = ({ images, width }) => {
  const galleryImages = images.map((image) => ({
    itemImageSrc: image.url,
    thumbnailImageSrc: image.url,
  }));
  const responsiveOptions = [
    {
      breakpoint: '991px',
      numVisible: 4,
    },
    {
      breakpoint: '767px',
      numVisible: 3,
    },
    {
      breakpoint: '575px',
      numVisible: 1,
    },
  ];
  const itemTemplate = (item) => {
    return (
      <div
        style={{
          backgroundImage: `url(${item.itemImageSrc})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          minWidth: 500,
          height: 700,
        }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <div
        style={{
          backgroundImage: `url(${item.thumbnailImageSrc})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: 80,
          height: 60,
          borderRadius: 5,
        }}
      />
    );
  };

  return (
    <Galleria
      value={galleryImages}
      responsiveOptions={responsiveOptions}
      numVisible={4}
      style={{
        width,
        // backgroundColor: 'var(--gray-100)',
        borderRadius: 5,
      }}
      item={itemTemplate}
      thumbnail={thumbnailTemplate}
      changeItemOnIndicatorHover={true}
    />
  );
};

export default Gallery;
