export const items = (collections, navigate) => {
  const itemRenderer = (item) => (
    <a
      className="flex align-items-center p-menuitem-link"
      onClick={() => navigate(`/item?id=${item._id}`)}
    >
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
    </a>
  );

  const handleMouseEnter = (event) => {
    event.target.parentElement.click();
  };

  const collectionRenderer = (item) => (
    <a
      className="flex align-items-center p-menuitem-link"
      onClick={(e) =>
        e.isTrusted
          ? navigate(`/collection?categoryName=${item.categoryName}`)
          : undefined
      }
      onMouseEnter={handleMouseEnter}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span className={item.icon} />
      <span style={{ paddingLeft: 4, paddingRight: 4 }} className="mx-2">
        {item.label}
      </span>
      <span className="pi pi-angle-down" />
    </a>
  );

  const subCollectionRenderer = (item) => (
    <a
      className="flex align-items-center p-menuitem-link"
      onClick={(e) => {
        e.isTrusted
          ? navigate(
              `/collection?categoryName=${item.categoryName}&subCategoryName=${item.subCategoryName}`,
            )
          : undefined;
      }}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span className="mx-2">{item.label}</span>
      <span className={item.icon} />
    </a>
  );

  const panelCollections = { Pintura: [], Dibujo: [], Boceto: [] };
  collections?.map((item) => {
    if (item.subCategory === 'empty' || !item.subCategory) {
      panelCollections[`${item.category}`]?.push({
        label: item.name,
        _id: item._id,
        template: itemRenderer,
      });
    } else {
      const element = panelCollections[`${item.category}`]?.find(
        (element) => element.label === item.subCategory,
      );
      if (element) {
        panelCollections[`${item.category}`] = panelCollections[
          `${item.category}`
        ].map((elementCat) => {
          if (elementCat.label === item.subCategory) {
            return {
              label: item.subCategory,
              template: subCollectionRenderer,
              categoryName: item.category,
              subCategoryName: item.subCategory,
              icon: 'pi pi-angle-right',
              items: [
                ...element.items,
                {
                  label: item.name,
                  _id: item._id,
                  template: itemRenderer,
                },
              ],
            };
          } else {
            return elementCat;
          }
        });
      } else {
        panelCollections[`${item.category}`]?.push({
          label: item.subCategory,
          template: subCollectionRenderer,
          categoryName: item.category,
          subCategoryName: item.subCategory,
          icon: 'pi pi-angle-right',
          items: [
            {
              label: item.name,
              _id: item._id,
              template: itemRenderer,
            },
          ],
        });
      }
    }
  });
  const subCategories = ['Retratos', 'Mascotas', 'Vermilion'];
  const sortPanel = (arrayCollection = []) =>
    arrayCollection.sort((a, b) => {
      const priorityA = subCategories.includes(a.label) ? 0 : 1;
      const priorityB = subCategories.includes(b.label) ? 0 : 1;
      return priorityA - priorityB;
    });
  return [
    {
      label: 'Pinturas',
      icon: 'pi pi-palette',
      categoryName: 'Pintura',
      template: collectionRenderer,
      items: sortPanel(panelCollections['Pintura']),
    },
    {
      label: 'Dibujos',
      icon: 'fa-solid fa-pencil',
      categoryName: 'Dibujo',
      template: collectionRenderer,
      items: sortPanel(panelCollections['Dibujo']),
    },
    {
      label: 'Bocetos',
      icon: 'fa-solid fa-eraser',
      categoryName: 'Boceto',
      template: collectionRenderer,
      items: sortPanel(panelCollections['Boceto']),
    },
  ];
};
