import React, { Fragment } from 'react';
import { useCollections } from 'Providers/CollectionsProviders';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { formatNumberWithDots } from 'components/ContainerCollections/components/GaleryCollections/components/Item/utils';

export const itemTemplate = ({ item, quanty }, navigate, storedValue) => {
  const { changeQuantyInShoppingCart, deleteItemShoppingCart } =
    useCollections();

  const minusClick = () => () => {
    changeQuantyInShoppingCart(item._id, '-');
  };
  const addClick = () => () => {
    changeQuantyInShoppingCart(item._id, '+');
  };
  const deleteItemAction = () => {
    deleteItemShoppingCart(item._id);
  };

  const goToItemView = () => navigate(`/item?id=${item._id}`);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'var(--gray-100)',
        borderRadius: 5,
        marginTop: 16,
        color: 'var(--gray-800)',
      }}
    >
      <div
        style={{
          borderRadius: 5,
          minWidth: 120,
          height: 140,
          cursor: 'pointer',
        }}
        onClick={goToItemView}
      >
        <div
          style={{
            backgroundImage: `url(${item.images.find((item) => item.mainImage === true)?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            minWidth: 120,
            height: 140,
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          padding: 16,
          width: '50%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ fontWeight: '600' }}>{'Nombre del artículo'}</div>
          <div>{item.name}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ fontWeight: '600' }}>{'Categoría'}</div>
          <div> {item.category}</div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          padding: 8,
          width: '25%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            minWidth: 110,
          }}
        >
          <div style={{ fontWeight: '600' }}>
            {!storedValue?.preferenceId ? 'Cantidad' : ''}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 8,
              width: 110,
            }}
          >
            {!storedValue?.preferenceId ? (
              <Fragment>
                <Button
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: 'var(--gray-300)',
                    border: 0,
                  }}
                  icon="pi pi-minus"
                  onClick={minusClick()}
                />
                <InputText
                  keyfilter="int"
                  placeholder="Integers"
                  value={quanty}
                  style={{
                    borderColor: 'var(--gray-300)',
                    backgroundColor: 'var(--gray-50)',
                    color: 'var(--gray-900)',
                    textAlign: 'center',
                    width: 40,
                    height: 30,
                  }}
                />
                <Button
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: 'var(--gray-300)',
                    border: 0,
                  }}
                  icon="pi pi-plus"
                  onClick={addClick()}
                />
              </Fragment>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            minWidth: 110,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ fontWeight: '600' }}>{'Precio'}</div>
              <div> {`$${formatNumberWithDots(quanty * item.price)} ARS`}</div>
            </div>
            {!storedValue?.preferenceId && (
              <Button
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: 'var(--gray-300)',
                  color: 'var(--gray-700)',
                  border: 0,
                  alignSelf: 'flex-end',
                }}
                icon="pi pi-trash"
                onClick={deleteItemAction}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
