import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';
import { useCollections } from 'Providers/CollectionsProviders';
import { Badge } from 'primereact/badge';
import { useNavigate } from 'react-router-dom';
import { useSessionStorageInternal } from 'Providers/SessionStorageProvider';

const ShoppingCartBadge = () => {
  const { shoppingCart } = useCollections();
  // const [storedValue, setStoredValue, minutesLeft, secondsLeft, reset] =
  //   useSessionStorageInternal();
  const navigate = useNavigate();
  const [shoppingCartVisible, setShoppingCartVisible] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const cart = document.getElementById('shoppingCart');
      const footer = document.getElementById('footer');
      if (
        typeof cart?.getBoundingClientRect === 'function' &&
        typeof footer?.getBoundingClientRect === 'function'
      ) {
        const cartRect = cart?.getBoundingClientRect?.();
        const footerRect = footer?.getBoundingClientRect?.();
        if (cartRect.bottom > footerRect.top) {
          cart.style.bottom = footer.clientHeight + 20 + 'px';
        } else {
          cart.style.bottom = '20px';
        }
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname === '/shopping_cart') setShoppingCartVisible(false);
  }, []);

  const goToShoppingCart = () => {
    navigate(`/shopping_cart`);
  };

  if (!shoppingCartVisible) return;

  return (
    <div className={styles.shoppingCart} id="shoppingCart">
      <i
        className="fa-solid fa-cart-shopping p-overlay-badge"
        style={{
          fontSize: '1.75rem',
          color: 'var(--gray-700)',
        }}
        onClick={goToShoppingCart}
      >
        <Badge
          style={{
            padding: 0,
            minWidth: '1.1rem',
            height: '1.1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          value={shoppingCart.length}
        ></Badge>
      </i>
      {/* {minutesLeft !== null && (
        <div
          style={{
            backgroundColor: 'var(--gray-300)',
            opacity: 0.7,
            width: 30,
            height: 10,
            display: 'flex',
            position: 'absolute',
            bottom: 10,
            left: 12.5,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'yellow',
              fontSize: '0.7em',
              fontWeight: 600,
            }}
          >{`${minutesLeft}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`}</div>
        </div>
      )} */}
    </div>
  );
};

export default ShoppingCartBadge;
