import React from 'react';
import { DataScroller } from 'primereact/datascroller';
import { itemTemplate } from '../ItemTemplate';
import ContactData from './components/ContactData';
import OrderPaymentInCurse from './components/OrderPaymentInCurse';
import { useCollections } from 'Providers/CollectionsProviders';
import { useNavigate } from 'react-router-dom';

const ListItemCard = ({
  eventData,
  goToMercadoPagoPayment,
  storedValue,
  setLoading,
}) => {
  const { shoppingCart } = useCollections();
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '60%', padding: '0 16px 0 16px', minWidth: 430 }}>
        <DataScroller
          value={shoppingCart}
          itemTemplate={(item) => itemTemplate(item, navigate, storedValue)}
          rows={shoppingCart.length}
          scrollHeight={`${eventData.height * 0.572}px`}
          inline
          header={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '4px 0 4px 0',
              }}
            >
              <div>{'Carrito de compras'}</div>
              {/* <div>{`${shoppingCart.length} Items`}</div> */}
            </div>
          }
        />
      </div>
      <div
        style={{
          width: '40%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minWidth: 370,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '2px 0px 2px 0px',
          }}
        >
          {'Detalle de compra'}
        </div>
        <div
          style={{
            width: '100%',
            minHeight: `${eventData.height * 0.572}px`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingTop: 20,
            fontSize: '0.7em',
            fontFamily: 'sans-serif',
            textAlign: 'right',
          }}
        >
          {!storedValue?.preferenceId ? (
            <ContactData
              goToMercadoPagoPayment={goToMercadoPagoPayment}
              eventData={eventData}
            />
          ) : (
            <OrderPaymentInCurse setLoading={setLoading} />
          )}
        </div>
      </div>
    </div>
  );
};
export default ListItemCard;
